<template>
  <div>
    <b-card no-body class="py-2">
      <!-- Le haut du tableau contenant les barre de recherche et bouton d'ajout de nouvelle taxe -->

      <b-row class="px-2">
        <!-- Per Page -->

        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start  "
        >
          <label>Entrées</label>

          <v-select
            v-model="state.perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="qData.perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />

          <b-button
            variant="primary"
            @click="modeModal = 'e-add-new-article'"
            :to="{ name: 'FactureList' }"
          >
            <feather-icon icon="FileTextIcon" class="mx-auto" />
            Liste des factures
          </b-button>
        </b-col>

        <!-- Search -->

         <b-col cols="12" md="6" class="mt-1">
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="state.filter"
                class="d-inline-block "
                placeholder="Rechercher par : nom de compte, code, montant, date..."
              />
            </b-input-group>
          </div>
        </b-col>
      </b-row>

      <!-- Loader -->
      <q-loader-table
        :success="state.success"
        :empty="state.empty"
        :warring="state.warring"
      />

      <b-table
        hover
        responsive
        primary-key="id_versement"
        :per-page="state.perPage"
        :current-page="state.currentPage"
        :items="payments"
        :fields="qData.tableColumns"
        :filter="state.filter"
        show-empty
        empty-text="Aucun versements enregistré"
        class="bg-white  text-left"
        v-if="state.success === true"
      >
        <template #cell(compte)="data">
          <div class="py-1">
            <span variant="info" class="text-uppercase font-weight-bolder">
              {{ data.item.compte }}
            </span>
          </div>
        </template>

        <template #cell(montant)="data">
          <div class="py-50">
            <span> {{ data.item.montant }} </span>
          </div>
        </template>

        <!-- Bouton d'action -->

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap py-50">
            <feather-icon
              @click="viewDetaiBillPayments(data.item.factures.id)"
              icon="EyeIcon"
              size="16"
              class="cursor-pointer"
            />

         </div>   
        </template>
      </b-table>

      <!-- Les boutons de pagination -->

      <div class="mx-2 mb-2 pb-1">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"></span>
          </b-col>

          <!-- Pagination -->

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="state.currentPage"
              :total-rows="payments.length"
              :per-page="state.perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>

              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormFile,
  BModal,
  BFormInput,
  BFormGroup,
  BButton,
  VBModal,
  BForm,
  BLink,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
  BImg,
} from 'bootstrap-vue';

import Ripple from 'vue-ripple-directive';

import { required, email } from '@validations';

import { ValidationProvider, ValidationObserver } from 'vee-validate';

import vSelect from 'vue-select';

import URL from '@/views/pages/request';

import axios from 'axios';

import { formatDate } from '@/@core/utils/filter';
import {
  toast_destory,
  toast_error,
  toast_loading,
  toast_sucess,
} from '@/utils/qToast';
import { qData } from './qVersementServices';
import { onMounted, ref, reactive } from '@vue/composition-api';
import moment from 'moment';
import QLoaderTable from '@/components/__partials/loaders/qLoaderTable.vue';
import qDeviseUtils from '@/utils/qDeviseUtils';
import { qDecrypted } from '@/services/qeCrypt';

export default {
  components: {
    vSelect,
    BRow,
    BFormFile,
    BCol,
    BFormInput,
    BButton,
    BModal,
    BFormGroup,
    VBModal,
    BForm,
    BImg,
    BLink,
    BForm,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    QLoaderTable,
  },

  directives: {
    Ripple,
  },

  setup(props, { root }) {
    const state = reactive({
      perPage: 30,
      currentPage: 1,
      filter: '',
      success: false,
      empty: false,
      warring: false,
      loading: false,
    });
    const payments = ref([]);
    const getBill = ref([])

    onMounted(async () => {
      document.title = 'Versements - Ediqia';
      await getPayments();
    });

    // Formater Money
    const formatter = (amount) => {
      const dataToDecrypt = localStorage.getItem('entreprise_info');
      return qDeviseUtils.formatter(qDecrypted(dataToDecrypt), amount);
    };

    // Get all payments
    const getPayments = async () => {
      try {
        const { data } = await axios.get(URL.VERSEMENT_LIST);
        
        if (data) {
          getBill.value = data;
          

          for (let i = 0; i < getBill.value[0].length; i++) {
            const payment = getBill.value[0][i];

            for (let v = 0; v < payment.versements.length; v++) {
              const vers = payment.versements[v];

              payments.value.unshift({
                id_versement: vers.id,
                compte_id: payment.libelle,
                compte: payment.libelle,
                facture_id: vers.facture_id,
                code: vers.code, //Code of versement
                code_invoice: vers.factures.code,
                amount: formatter(parseInt(vers.montant)),
                montant: formatter(parseInt(vers.montant)),
                factures: vers.factures,
                created_at: moment(vers.created_at)
                  .format('DD-MM-YY à HH:MM')
                  .toString(),
                created_at_time: moment(vers.created_at)
                  .toDate()
                  .getTime()
                  .toString(),
              });
            }
          }
          payments.value.length === 0
            ? (state.empty = true)
            : (state.success = true);
          payments.value.sort((x, y) => x.created_at_time < y.created_at_time);
        }
      } catch (error) {
        state.warring = true;
        console.log(error);
      }
    };

    const viewDetaiBillPayments = (id) => {
      console.log(payments.value);
      let addDataToLocal = payments.value.filter((item) => {
            return item.factures.id === id;
          });
console.log(addDataToLocal);
      const dataInvoice = addDataToLocal[0].factures
      dataInvoice.entreprise = getBill.value[1][0];

      localStorage.setItem('facture', JSON.stringify(dataInvoice));
      root.$router.push('/facture/view');
    };

    

    return {
      state,
      qData,
      formatter,
      payments,

      //
      getPayments,
      viewDetaiBillPayments,
      //
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.add-new-client-header {
  padding: $options-padding-y $options-padding-x;

  color: $success;

  &:hover {
    background-color: rgba($success, 0.12);
  }
}

.table-base {
  margin: 30px auto 0;
}

.tableau {
  box-shadow: 0px 6px 46px -21px rgba(0, 0, 0, 0.75);
}

.table-card {
  width: 100%;

  margin: auto;

  border-radius: 13px;
}

.table-card thead tr {
  border-radius: 13px;

  background-color: rgb(68, 68, 68) !important;
}

.table-card thead tr th {
  background-color: rgb(68, 68, 68) !important;

  color: white;
}

.add-btn {
  position: absolute;

  right: 0;

  top: -50px;

  background-color: #450077;
}

.none {
  display: none;
}

.block {
  display: inline-block;
}

.caticon {
  color: #ff3333;
}

.caticon:hover {
  cursor: pointer;
}
.red:after {
  content: ' *';
  color: red;
}
</style>
