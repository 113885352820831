import { reactive, ref } from '@vue/composition-api';



const tableColumns = reactive([
  {
    key: 'code',
    label: 'Codes',
  },
  {
    key: 'code_invoice',
    label: 'N. Facture',
  },
  {
    key: 'compte',
    label: 'Comptes',
  },
  {
    key: 'montant',
    label: 'Montants',
  },
  {
    key: 'created_at',
    label: "date d'ajout",
  },
  {
    key: 'actions',
    label: 'Actions',
  },
]);
const perPageOptions = [30, 60, 70, 100, 120];

export const qData = {
  tableColumns,
  perPageOptions,
};
